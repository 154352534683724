<template>
  <div class="BaseInfo_wrap">
    <el-tabs v-model="currView">
      <el-tab-pane label="基本信息" name="BasicInfoChildren"></el-tab-pane>
      <el-tab-pane label="活动规则" name="ActivityRules"></el-tab-pane>
      <el-tab-pane label="用户隐私协议" name="PrivacyProtocol"></el-tab-pane>
      <el-tab-pane label="广告位设置" name="ADSpace"></el-tab-pane>
      <el-tab-pane label="高级设置" name="AdvancedSetup"></el-tab-pane>
      <el-tab-pane label="分享权限设置" name="ShareSet"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
    <!-- <el-dialog
      width="30%"
      :title="'已'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
      :visible.sync="showBaseData"
    >
      <el-form label-width="120px" :model="baseData">
        <el-form-item
          :label="'真实'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
        >
          <el-input readonly="readonly" :value="baseData.realData"></el-input>
        </el-form-item>
        <el-form-item label="是否开启">
          <el-switch v-model="baseData.open"></el-switch>
          <span
            class="ml10"
          >关闭后，显示真实的{{dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名'}}人次</span>
        </el-form-item>
        <el-form-item
          :label="'虚拟'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
        >
          <el-input type="number" v-model="baseData.fakeData"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="showBaseData = false">取消</el-button>
          <el-button type="primary" @click="saveChange">保存更改</el-button>
        </el-form-item>
      </el-form>

      <div class="fz14 cae" style="line-height: 24px;">
        <p>注：</p>
        <ul v-if="dataKey == 'share'">
          <li>• 已分享人次=虚拟分享人次(固定)+真实分享人次(动态)</li>
          <li>• 如，当真实分享人次为1000时，虚拟分享人次为2000，总分享人次(3000)=真实分享人次</li>
          <li>• (1000)+虚拟分享人次(2000)，同时随着实际分享人次增加总分享人次不断增加</li>
        </ul>
        <ul v-else-if="dataKey == 'sign'">
          <li>• 已报名人数=虚拟报名人数(固定)+真实报名人数(动态)</li>
          <li>• 如，当真实报名人数为1000时，虚拟报名人数为2000，总报名人数(3000)=真实报名人数</li>
          <li>• (1000)+虚拟报名人数(2000)，同时随着实际报名人数增加总报名人数不断增加</li>
        </ul>
        <ul v-else-if="dataKey == 'viewer'">
          <li>• 已浏览人次=虚拟浏览人次(固定)+真实浏览人次(动态)</li>
          <li>• 如，当真实浏览人次为1000时，虚拟浏览人次为2000，总浏览人次(3000)=真实浏览人次</li>
          <li>• (1000)+虚拟浏览人次(2000)，同时随着实际浏览人次增加总浏览人次不断增加</li>
        </ul>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";

export default {
  name: "baseInfo", // 活动基本信息
  components: {
    BasicInfoChildren: () => import("./BaseInfoChildren/BasicInfo"),
    ADSpace: () => import("./BaseInfoChildren/ADSpace"),
    AdvancedSetup: () => import("./BaseInfoChildren/AdvancedSetup"),
    ActivityRules: () => import("./BaseInfoChildren/ActivityRules"),
    PrivacyProtocol: () => import("./BaseInfoChildren/PrivacyProtocol"),
    ShareSet: () => import("./BaseInfoChildren/ShareSet"),
  },
  data() {
    return {
      currView: "BasicInfoChildren",
      upload: null, // 上传对象
      activityID: '0', // 活动ID
      baseInfo: {
        id: "",
        name: "",
        timeRange: [],
        actStartTime: "",
        actEndTime: "",
        areaList: [],
        provinceId: "",
        poster: "",
        complainMobile: "",
        liveStartTime: "",
        liveUrl: ""
      },
      rules: {
        actName: [
          { required: true, message: "请输入活动名称", trigger: "blur" }
        ],
        poster: [
          { required: true, message: "请添加活动海报图", trigger: "blur" }
        ],
        timeRange: [
          { required: true, message: "请选择活动时间", trigger: "blur" }
        ],
        provinceId: [
          { required: true, message: "请选择活动区域", trigger: "blur" }
        ]
      },
      pickerOptionsStart: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().toLocaleDateString()).getTime()
          );
        }
      },
      options: [
        { value: 100, label: "广东" },
        { value: 200, label: "北京" },
        { value: 300, label: "上海" }
      ],
      radio: 0,
      input_test: "",
      tableData: [
        {
          name: "首页",
          link: "首页",
          icon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg",
          selIcon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg"
        },
        {
          name: "首页",
          link: "首页",
          icon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg",
          selIcon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg"
        },
        {
          name: "首页",
          link: "首页",
          icon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg",
          selIcon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg"
        },
        {
          name: "首页",
          link: "首页",
          icon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg",
          selIcon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg"
        },
        {
          name: "首页",
          link: "首页",
          icon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg",
          selIcon:
            "https://i2.vzan.cc/image/liveImage/jpeg/2019/12/10/1417598eb294fb4ce24563914975acc01f69dd.jpeg"
        }
      ],
      switchValue: false,
      optimizationTable: [
        {
          name: "已报名人数",
          truthful: 5,
          virtual: 5
        },
        {
          name: "已报名人数",
          truthful: 5,
          virtual: 5
        },
        {
          name: "已报名人数",
          truthful: 5,
          virtual: 5
        }
      ],
      showBaseData: false,
      dataKey: "",
      baseData: {
        realData: 0,
        open: false,
        fakeData: 0
      }
    };
  },
  methods: {
    // 获取活动信息
    async getActivityDetails() {
      try {
        const { data } = await ActivityAjax.getActivityDetails(this.activityID);
        this.baseInfo.timeRange.push(data.actStartTime, data.actEndTime);
        this.baseInfo = Object.assign(this.baseInfo, data);
        console.log(this.baseInfo);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.baseInfo.poster = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    changeProvince() {
      this.$forceUpdate();
    },
    settingData(index, key) {
      this.dataKey = key;
      this.showBaseData = true;
      this.baseDataIndex = index;
      // if (index >= 0) {
      //   this.dataKey = key;
      //   this.showBaseData = true;
      //   this.baseDataIndex = index;
      //   this.baseData = clone(this.preheatData[index]);
      // } else {
      //   // const baseurl = `${location.origin}${location.pathname}?zbid=${this.zbid}#`;
      //   if (key == "sign") {
      //     this.$router.push(
      //       `/homelive/settingPreheat/${this.pageid}/${this.tpid}/signin-2`
      //     );
      //   } else if (key == "guide") {
      //     this.$router.push(`/homelive/premoteManage/${this.tpid}`);
      //   } else if (key == "gorder") {
      //     // 家具订单类型
      //     // this.$router.push(`/Asset/OrderManagement/1207`);
      //     window.location.href = `/nlive/zbproductorders?zid=${this.zbid}&topicid=${this.tpid}&terminal_type=3`;
      //   } else if (key == "korder") {
      //     this.$router.push(
      //       `/homelive/seckillOrders/${this.pageid}/${this.tpid}/0`
      //     );
      //   }
      //   /*  else if (key == "subscribe") {
      //     // signin-2-1 2: 查看用户报名信息 1: 预约状态
      //     this.$router.push(
      //       `/homelive/settingPreheat/${this.pageid}/${this.tpid}/signin-2-1`
      //     );
      //   } */
      // }
    },
    saveChange() {
      this.$message.error("保存");
      // const dataNum = Number(this.baseData.fakeData);
      // const num = Number.isNaN(dataNum);
      // if (num) {
      //   this.$message.error("请输入正确的数字类型");
      //   return;
      // }
      // // 最大值限制
      // if (dataNum > 99999999) {
      //   this.$message.error("虚拟数不得超过 99999999");
      //   return;
      // }
      // if (this.dataKey == "share") {
      //   this.baseDataModel.OpenShare = this.baseData.open;
      //   this.baseDataModel.ShareNum = this.baseData.fakeData;
      // } else if (this.dataKey == "sign") {
      //   this.baseDataModel.OpenJoin = this.baseData.open;
      //   this.baseDataModel.JoinNum = this.baseData.fakeData;
      // } else if (this.dataKey == "viewer") {
      //   this.baseDataModel.OpenView = this.baseData.open;
      //   this.baseDataModel.ViewNum = this.baseData.fakeData;
      // }
      // const data = {
      //   setting: JSON.stringify(this.baseDataModel),
      //   opertype: "setting",
      //   zbid: this.zbid,
      //   topicid: this.tpid,
      //   Id: this.pageid
      // };
      // SaveHomeLiveInfo(data)
      //   .then(res => {
      //     this.preheatData[this.baseDataIndex].open = this.baseData.open;
      //     this.preheatData[
      //       this.baseDataIndex
      //     ].fakeData = this.baseData.fakeData;
      //     this.$message.success(res.Msg);
      //     this.showBaseData = false;
      //     this.$nextTick(() => {
      //       this.getData();
      //     });
      //   })
      //   .catch(errorMsg => {
      //     this.$message.error(errorMsg);
      //   });
    }
  },
  mounted() {
    this.activityID = this.$route.params.activityID;
  }
};
</script>

<style lang="scss">
.BaseInfo_wrap {
  .el-tabs__content {
    .el-form-item {
      &.province {
        .el-form-item__content {
          div {
            margin-right: 10px;
          }
          .el-input {
            width: 200px;
          }
        }
      }
      &.up_poster {
        .el-form-item__content {
          display: flex;
          align-items: center;
          .el-upload--text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 178px;
          }
        }
      }
      .el-input {
        width: 400px;
      }
    }
    .left_advertising {
      & > div {
        margin-top: 12px;
        .el-upload--text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 178px;
          height: 178px;
        }
      }
    }
    .nav_settings {
      .cell {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .data_optimization {
      // margin: 0 20px;
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 2;
      }
      ul {
        li {
          line-height: 1.5;
        }
      }
    }
  }
  .color_E6A23C {
    color: #e6a23c;
  }
}
</style>
