var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInfo_wrap" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.currView,
            callback: function($$v) {
              _vm.currView = $$v
            },
            expression: "currView"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "基本信息", name: "BasicInfoChildren" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "活动规则", name: "ActivityRules" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "用户隐私协议", name: "PrivacyProtocol" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "广告位设置", name: "ADSpace" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "高级设置", name: "AdvancedSetup" }
          }),
          _c("el-tab-pane", {
            attrs: { label: "分享权限设置", name: "ShareSet" }
          })
        ],
        1
      ),
      _c(
        "keep-alive",
        [
          _c(_vm.currView, {
            tag: "component",
            attrs: { activityID: _vm.activityID }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }